var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[_vm.s.container, _vm.theme.profile.profile]},[_c('div',{class:_vm.s.header},[_c('span',{class:_vm.s.headerText},[_vm._v(" Settings ")])]),_c('div',{class:_vm.s.contentContainer},[_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" Your Password ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" • • • • • • • • • • • ")])]),_c('span',{class:_vm.s.linkText,on:{"click":function($event){_vm.$router.push('/profile/changepassword').catch(() => { /* navigation error */ })}}},[_vm._v(" Change Password ")])]),_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" E-mail Authentication ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")])]),_c('SwitchControl',{attrs:{"value":true,"disabled":""}})],1),_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" Two-Factor Authentication ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" Google Authenticator • TOTP ")])]),_c('SwitchControl',{attrs:{"disabled":_vm.isMFASwitchDisabled,"value":_vm.isMfaEnabled},on:{"change":function($event){return _vm.toggleMFA($event)}}})],1),_c('div',{class:[_vm.s.row, _vm.s.separator]}),_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" Wallet Address Management ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" "+_vm._s(_vm.addressesCount)+" "+_vm._s(_vm.pluralize('Address', _vm.addressesCount, true))+" / "+_vm._s(_vm.assetsCount)+" "+_vm._s(_vm.pluralize('Asset', _vm.assetsCount))+" ")])]),_c('span',{class:_vm.s.linkText,on:{"click":() => {
                    this.$store.commit(_vm.SET_LAST_ROUTE('/profile'));
                    _vm.$router.push({
                        path: '/profile/addresses-management',
                        query: {
                            previousRoute: _vm.$router.currentRoute.path,
                        },
                    }).catch(() => { /* navigation error */ });
                }}},[_vm._v(" Manage ")])]),_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" Institutional Groups ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" "+_vm._s(_vm.groupsCount)+" "+_vm._s(_vm.pluralize('Group', _vm.groupsCount))+" ")])]),_c('span',{class:_vm.s.linkText,on:{"click":_vm.openGroups}},[_vm._v(" Manage ")])]),_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" API-Keys • API Requests ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" "+_vm._s(this.apiKeys ? this.apiKeys.length : 0)+" "+_vm._s(_vm.pluralize('API-Key', this.apiKeys ? this.apiKeys.length : 0))+" "),_c('span',[_vm._v("•")]),_vm._v(" "+_vm._s(this.currentUserQuota ? this.currentUserQuota.getSeparatedDigits() : 0)+" "+_vm._s(_vm.pluralize('Request', this.currentUserQuota ? this.currentUserQuota : 0))+" ")])]),_c('span',{class:_vm.s.linkText,on:{"click":_vm.openManageKeysPage}},[_vm._v(" Manage ")])]),(_vm.status === 'Corporate' && _vm.isKycVerified)?_c('div',{class:_vm.s.row},[_c('div',{class:_vm.s.col},[_c('span',{class:_vm.s.subHeaderText},[_vm._v(" Corporate Team Members ")]),_c('span',{class:_vm.s.contentText},[_vm._v(" "+_vm._s(_vm.corporateTeamMembersCount)+" Members ")])]),_c('span',{class:_vm.s.linkText,on:{"click":_vm.openCorporateTeamManagement}},[_vm._v(" Manage ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }